import React, {useEffect, useState} from 'react';
import {Avatar, AvatarImage, AvatarFallback} from '@/components/ui/avatar';
import {Button} from "@/components/ui/button";
import {useAuth} from "@/contexts/AuthContext";

import {
    Dialog,
    DialogContent, DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {
    ArrowDown,
    ArrowLeftIcon,
    ArrowRightIcon,
    ArrowUp,
    ArrowUp01,
    BeanIcon, Bot, CakeIcon,
    EditIcon, EyeIcon, IceCream2Icon, IceCreamIcon,
    LinkIcon, LockIcon, MessageCircle, PlusCircle, SettingsIcon, ShareIcon,
    SkipBackIcon
} from "lucide-react";
import {BACKEND_BASE_URL, FRONTEND_BASE_URL} from "@/config";
import axios from "axios";
import {IconRight} from "react-day-picker";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "@/components/ui/tooltip";
import CreateBotDialog from "@/components/createBotDialog";
import {useChat} from "@/contexts/ChatContext";
import {ShareDialog} from "@/components/shareLinkDialog";
import {useNavigate} from "react-router-dom";
import EditBotDialog from "@/components/editBotDialog";


const EditProfileDialog = ({user}) => {
    const {jwt, updateUser} = useAuth();
    const [newName, setNewName] = useState(user.username);
    const [newDescription, setNewDescription] = useState(user.dio);
    const [newAvatarUrl, setNewAvatarUrl] = useState(user.avatarUrl);
    // 添加一个新的状态来追踪dialog是否打开
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [view, setView] = useState('editProfile');

    const handleNameChange = event => setNewName(event.target.value);
    const handleDescriptionChange = event => setNewDescription(event.target.value);

    const [uploadError, setUploadError] = useState(null); // 用于跟踪上传错误
    const [tempAvatarUrl, setTempAvatarUrl] = useState(user.avatarUrl); // 临时头像URL
    const [isUploading, setIsUploading] = useState(false);
    const [avatarPrompt, setAvatarPrompt] = useState("");
    const [newCreatedAvatarUrl, setNewCreatedAvatarUrl] = useState("");

    const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);
    const [createAvatarError, setCreatAvatarError] = useState(null);
    // isAvatarReady tracks whether the avatar image has finished loading
    const [isAvatarReady, setIsAvatarReady] = useState(false);
    const goToCreateAvatar = () => {
        setView('createAvatar');
    };

    const goBackToEditProfile = () => {
        setView('editProfile');
    };

    const handleCreateAvatar = async () => {

        try {
            setIsLoadingAvatar(true); // Avatar creation started
            setIsAvatarReady(false); // Avatar image is not ready
            setCreatAvatarError(null);
            const response = await axios.post(`${BACKEND_BASE_URL}/api/users/create-avatar`, {avatarPrompt: avatarPrompt}, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                },
            });

            if (response.status === 200) {
                const data = response.data;
                setNewCreatedAvatarUrl(data.avatar_url); // 更新新创建头像的URL
                setIsLoadingAvatar(false);
                setIsAvatarReady(true);

            } else {
                setCreatAvatarError("create error")
                setIsAvatarReady(false); // Avatar image is not ready
                setIsLoadingAvatar(false);

                // 在此处处理错误...
            }
        } catch (error) {
            setCreatAvatarError("create error")
            setIsAvatarReady(false); // Avatar image is not ready
            setIsLoadingAvatar(false);
        }
    };

    const handleImageLoad = () => {
        setNewAvatarUrl(tempAvatarUrl); // When image finishes loading, update newAvatarUrl
        setIsUploading(false);
        setIsUploading(false)
    };

    const handleImageLoadAvatar_ = () => {
        setIsAvatarReady(true);
        //setNewAvatarUrl(tempAvatarUrl); // When image finishes loading, update newAvatarUrl
        setIsLoadingAvatar(false);
    }

    const setNewAvatarUrl_ = (url) => {
        setNewAvatarUrl(url);
        setTempAvatarUrl(url);
        setView("editProfile");

    }

    const handleAvatarUpload = async (event) => {
        setIsUploading(true);
        setUploadError(null);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${BACKEND_BASE_URL}/api/users/upload-avatar`, formData, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                const data = response.data;
                setTempAvatarUrl(data.avatar_url);
                setUploadError(null); // 如果上传成功，清除错误信息
            } else {
                setIsUploading(false)
                setUploadError('upload error'); // 你也可以从响应中获取更详细的错误信息
            }
        } catch (error) {
            setIsUploading(false)
            setUploadError('upload error');
        }
    };


    const handleSaveChanges = async () => {
        await updateUser({
            username: newName,
            dio: newDescription,
            avatarUrl: newAvatarUrl,
        });

        // 在保存变化后，设置dialog为关闭
        setIsDialogOpen(false);
    };

    const setIsDialogOpen_ = (value) => {
        setIsDialogOpen(value);
        setUploadError(null);
        setIsUploading(false);
        setNewAvatarUrl(user.avatarUrl);
        setView('editProfile');
        setIsAvatarReady(false);
        setIsLoadingAvatar(false);
        setCreatAvatarError(null);
        setNewCreatedAvatarUrl(null);
    }

    return (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
                <Button onClick={() => setIsDialogOpen_(true)} variant="ghost"
                        className="h-8 w-8 p-2 rounded-full hover:shadow hover:bg-gray-200">
                    <EditIcon/>
                </Button>
            </DialogTrigger>
            {view === 'editProfile' && (
                <DialogContent className="sm:max-w-[550px]">
                    <DialogHeader className="pb-4">
                        <DialogTitle>Edit profile</DialogTitle>
                    </DialogHeader>
                    <div className="flex justify-center mt-4 relative">
                        <div className="flex items-end">
                            <label className="cursor-pointer relative">
                                <input type="file" accept="image/*" onChange={handleAvatarUpload} className="hidden"/>
                                <Avatar className="w-24 h-24">
                                    <AvatarImage src={newAvatarUrl || ''} alt="Avatar" onLoad={handleImageLoad}/>
                                    {tempAvatarUrl &&
                                        <img src={tempAvatarUrl} alt="Temp" hidden onLoad={handleImageLoad}/>}
                                    {<AvatarFallback>{user.nickname.charAt(0)}</AvatarFallback>}
                                </Avatar>
                                <div className="absolute bottom-1 right-1 bg-white rounded-full">
                                    <EditIcon className="h-6 w-6 text-gray-500"/>
                                </div>
                            </label>
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <label className="cursor-pointer relative ml-8">
                                            <ArrowRightIcon onClick={goToCreateAvatar} size={24}/>
                                        </label>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>Create avatar</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>

                        </div>
                    </div>
                    <div>
                        {isUploading &&
                            <div className="grid gap-4 py-4 flex justify-center items-center">
                                <div className="flex space-x-3">
                                    <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"></div>
                                    <div className="w-2 h-2 bg-yellow-500 rounded-full animate-bounce"></div>
                                    <div className="w-2 h-2 bg-red-500 rounded-full animate-bounce"></div>
                                </div>
                            </div>
                        }
                        {uploadError &&
                            <div
                                className="grid gap-4 py-4 text-lg text-red-500 text-center font-bold">{uploadError}</div>}
                    </div>

                    <div className="grid gap-4 py-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="name" className="text-left col-span-1">
                                Name
                            </Label>
                            <Input id="name" value={newName} onChange={handleNameChange} className="col-span-3"/>
                        </div>
                        <div className="grid grid-cols-4 py-4 items-center gap-4">
                            <Label htmlFor="description" className="text-left col-span-1">
                                Dio
                            </Label>
                            <textarea
                                className="border rounded-md h-20 w-full col-span-3"
                                id="description" value={newDescription} onChange={handleDescriptionChange}
                            />
                        </div>
                    </div>
                    <DialogFooter>
                        <Button type="submit" onClick={handleSaveChanges}>Save</Button>
                    </DialogFooter>
                </DialogContent>
            )}
            {view === 'createAvatar' && (
                <DialogContent className="sm:max-w-[550px]">
                    <DialogHeader className="flex justify-between">
                        <div className="flex items-center">
                            <button onClick={goBackToEditProfile}>
                                <ArrowLeftIcon size={24}/>
                            </button>
                            <DialogTitle className="ml-4">Create avatar</DialogTitle>
                        </div>
                    </DialogHeader>
                    <div className="p-4 rounded-md mt-4 ">
                        <div className="flex items-center cursor-pointer">
                            Please input avatar prompt
                        </div>
                        <div className="grid gap-4 py-4">
                            <textarea
                                className="border rounded-md h-20"
                                value={avatarPrompt}
                                onChange={event => setAvatarPrompt(event.target.value)}
                                placeholder="Input avatarPrompt"
                            />
                            <div className="flex justify-center mt-4 relative">
                                {isLoadingAvatar && <div>Loading...</div>} {/* Show loading state */}
                                {createAvatarError && <div>{createAvatarError}</div>} {/* Show error state */}

                                {newCreatedAvatarUrl && (
                                    <Avatar className="w-24 h-24">
                                        <img src={newCreatedAvatarUrl} alt="Created Avatar"
                                             onLoad={handleImageLoadAvatar_}/>
                                    </Avatar>
                                )}
                                {/*<AvatarFallback>{newName.charAt(0)}</AvatarFallback>*/}
                            </div>
                            <div className="flex justify-end">
                                <Button onClick={handleCreateAvatar} disabled={!avatarPrompt}>
                                    <IceCreamIcon/>create
                                </Button>
                                <Button onClick={() => setNewAvatarUrl_(newCreatedAvatarUrl)} disabled={!isAvatarReady}
                                        className="ml-4">
                                    SetAvatar
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            )}
        </Dialog>
    );
};

function Redirect(props: { to: string }) {
    return null;
}

const UserProfilePage = () => {
    const {user, isAuthenticated, loading} = useAuth();
    const [selectedTab, setSelectedTab] = useState('bots');
    const [isChange, setIsChange] = useState(false);
    const {createBot} = useChat();
    const [forceUpdate, setForceUpdate] = useState(0); // add this line


    useEffect(() => {
        setSelectedTab('bots');
        setForceUpdate(prevState => prevState + 1); // add this line
    },[isChange,setSelectedTab])
    const handleOncreated = () => {
        setIsChange(!isChange);
    }

    if (!isAuthenticated && !loading) {
        window.location.href = '/login';
        return null;
    }
    // Render null if not authenticated and loading
    if (!isAuthenticated && loading) {
        return null;
    }

    const handleCreate = () => {
        // 在这里处理创建机器人的功能
        console.log(`Create a new bot`);
    };

    console.log(isAuthenticated)
    // 渲染不同的tab页面
    const renderTabContent = () => {
        switch (selectedTab) {
            case 'bots':
                return <Bots key={isChange} />;
            // case 'timeline':
            //     return <div>Timeline content here.</div>
            // case 'followed':
            //     return <div>Followed content here.</div>
            // case 'following':
            //     return <div>Following content here.</div>
            default:
                return null;
        }
    };

    if (!user) {
        return null
    }

    return (
        <div className="bg-white max-w-screen-lg mx-auto p-8 w-3/4">
            <div className="flex items-center justify-between mb-6">
                <div className="flex items-center space-x-5">
                    <Avatar className="w-24 h-24">
                        <AvatarImage src={user.avatarUrl || ''} alt={user.avatarUrl}/>
                        {<AvatarFallback>{user.nickname.charAt(0)}</AvatarFallback>}
                    </Avatar>
                    <div>
                        <h2 className="text-2xl font-bold">{user.nickname}</h2>
                        <h3 className="text-gray-500">{user.username ? "@" + user.username : "No username"}</h3>
                        {user && user.dio && <p className="text-sm text-gray-500 mt-2">{user.dio}</p>}
                    </div>
                </div>
                <div className="flex space-x-2 justify-end">
                    <CreateBotDialog
                        trigger={
                            <Button variant="ghost" className="h-8 w-8 shrink-0 p-0 rounded-full hover:shadow text-neutral-500 hover:bg-neutral-800 hover:text-white">
                                <Bot size="16"/>
                            </Button>
                        }
                        onCreate={createBot}
                        onCreated={handleOncreated}
                    />
                    <ShareDialog
                        trigger={
                            <Button variant="ghost" className="h-8 w-8 shrink-0 p-0 rounded-full hover:shadow text-neutral-500 hover:bg-neutral-800 hover:text-white">
                                <ShareIcon size="16"/>
                            </Button>
                        }
                        userLink={`${FRONTEND_BASE_URL}/user/${user.username}`} // replace this with the actual user link
                    />
                    <EditProfileDialog user={user}/>
                </div>
            </div>
            <div className="border-b-2 border-gray-200">
                <div className="flex py-4 space-x-4">
                    <button
                        className={`px-6 py-2 rounded-md ${selectedTab === 'bots' ? 'bg-blue-500 text-white' : 'text-blue-500 border-blue-500 border'} hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50`}
                        onClick={() => setSelectedTab('bots')}>Bots
                    </button>
                    {/* Uncomment and adjust other buttons as needed */}
                    {/*<button*/}
                    {/*    className={`px-6 py-2 rounded-md ${selectedTab === 'timeline' ? 'bg-blue-500 text-white' : 'text-blue-500 border-blue-500 border'} hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50`}*/}
                    {/*    onClick={() => setSelectedTab('timeline')}>Timeline*/}
                    {/*</button>*/}
                    {/*<button*/}
                    {/*    className={`px-6 py-2 rounded-md ${selectedTab === 'followed' ? 'bg-blue-500 text-white' : 'text-blue-500 border-blue-500 border'} hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50`}*/}
                    {/*    onClick={() => setSelectedTab('followed')}>Followed*/}
                    {/*</button>*/}
                    {/*<button*/}
                    {/*    className={`px-6 py-2 rounded-md ${selectedTab === 'following' ? 'bg-blue-500 text-white' : 'text-blue-500 border-blue-500 border'} hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50`}*/}
                    {/*    onClick={() => setSelectedTab('following')}>Following*/}
                    {/*</button>*/}
                </div>
            </div>
            <div className="tab-content mt-6">
                {renderTabContent()}
            </div>
        </div>
    );






};


const Bots = (key) => {
    const [bots, setBots] = useState([]);
    const {jwt} = useAuth();
    const [selectedBotType, setSelectedBotType] = useState('public');
    const [publicBotsCount, setPublicBotsCount] = useState(0);
    const [sharedBotsCount, setSharedBotsCount] = useState(0);
    const [privateBotsCount, setPrivateBotsCount] = useState(0);
    const {createBot, createChannel} = useChat();
    const navigate = useNavigate();
    const [isChange, setIsChange] = useState(false);
    const [editingBot, setEditingBot] = useState(null);
    const [open, setOpen] = useState(false)


    useEffect(() => {
        const fetchBots = async () => {
            try {
                // 替换为你的API端点
                const response = await axios.get(`${BACKEND_BASE_URL}/api/users/bots`,
                    {headers: {
                            'Authorization': `Bearer ${jwt}`,
                            'Content-Type': 'multipart/form-data',
                        }
                },);
                const bots = response.data.map(bot => {
                    return Object.prototype.hasOwnProperty.call(bot, 'type') ? bot : {...bot, type: 'public'};
                });
                setBots(bots);
                const publicBots = bots.filter(bot => bot.type === 'public');
                const sharedBots = bots.filter(bot => bot.type === 'share-link');
                const privateBots = bots.filter(bot => bot.type === 'private');
                setPublicBotsCount(publicBots.length);
                setSharedBotsCount(sharedBots.length);
                setPrivateBotsCount(privateBots.length);
                setSelectedBotType('public');
            } catch (error) {
                console.error('Error fetching bots: ', error);
            }
        };
        fetchBots();
        console.log('ischage:'+ isChange)
    }, [jwt, isChange, key]);

    const startChat = async (user_id) => {
            await createChannel("direct", [user_id]);
            navigate("/chats");
    };

    const handleOncreated = () => {
        setIsChange(!isChange);
        console.log('ischang__e:' + isChange);

    }

    const handleEdit = (botId) => {
        setEditingBot(botId);
        setOpen(true)
    };

    const updateBotInfo = (botInfo) => {
        return  ""
    }


    return (
        <div>
            <div className="flex justify-around py-4 text-sm">
                <button className={`px-6 py-2 flex items-center ${selectedBotType === 'public' ? 'font-bold border-b-2 border-blue-500' : ''}`} onClick={() => setSelectedBotType('public')}>
                    <span>({publicBotsCount})</span> <EyeIcon className="h-4 w-4" />
                </button>
                <button className={`px-6 py-2 flex items-center ${selectedBotType === 'share-link' ? 'font-bold border-b-2 border-blue-500' : ''}`} onClick={() => setSelectedBotType('share-link')}>
                    <span>({sharedBotsCount})</span> <LinkIcon className="h-4 w-4" />
                </button>
                <button className={`px-6 py-2 flex items-center ${selectedBotType === 'private' ? 'font-bold border-b-2 border-blue-500' : ''}`} onClick={() => setSelectedBotType('private')}>
                    <span>({privateBotsCount})</span> <LockIcon className="h-4 w-4" />
                </button>
            </div>
            <div className="overflow-auto max-h-[500px]">
                {bots.filter(bot => bot.type === selectedBotType).length === 0 ?
                    <div className="flex justify-center items-center mt-4">
                        <CreateBotDialog
                            trigger={
                                <Button variant="ghost" className="h-8 w-8 shrink-0 p-0 rounded-full hover:shadow text-neutral-500 hover:bg-neutral-800 hover:text-white">
                                    <Bot size="16"/>
                                </Button>
                            }
                            onCreate={createBot}
                            onCreated={handleOncreated}
                        />
                    </div> :
                    bots.filter(bot => bot.type === selectedBotType).map((bot) => (
                        <div key={bot.id} className="flex justify-between items-center my-4">
                            <div className="flex items-center">
                                <Avatar className="w-12 h-12">
                                    <AvatarImage src={bot.avatar_url || ''} alt={bot.avatarUrl} />
                                    {<AvatarFallback>{bot.nickname.charAt(0)}</AvatarFallback>}
                                </Avatar>
                                <div className="ml-4">
                                    <h3 className="font-bold">{bot.nickname}</h3>
                                    <p className="text-gray-500">{bot.dio}</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4">
                                <Button onClick={() => startChat(bot.user_id)} variant="ghost" className="h-8 w-8 p-2 rounded-full hover:shadow hover:bg-gray-200">
                                    <MessageCircle />
                                </Button>
                                <Button onClick={() => handleEdit(bot.user_id)} variant="ghost" className="h-8 w-8 p-2 rounded-full hover:shadow hover:bg-gray-200">
                                    <SettingsIcon />
                                </Button>

                                <ShareDialog
                                    trigger={
                                        <Button variant="ghost" className="h-8 w-8 shrink-0 p-0 rounded-full hover:shadow text-neutral-500 hover:bg-neutral-800 hover:text-white">
                                            <ShareIcon size="16"/>
                                        </Button>
                                    }
                                    userLink={`${FRONTEND_BASE_URL}/user/${bot.user_id}`} // replace this with the actual user link
                                />
                            </div>
                        </div>
                    ))
                }
                {editingBot && (
                    <EditBotDialog
                        botId = {editingBot}
                        open={open}
                        setIsOpen={setOpen}
                        onUpdated={handleOncreated}
                    />
                )}
            </div>
        </div>
    );

};

export default UserProfilePage;
