import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";

import {FRONTEND_BASE_URL} from '@/config';
import {useAuth} from "@/contexts/AuthContext";

export default function CallbackPage() {
    const {provider} = useParams();
    const navigate = useNavigate();
    const { login } = useAuth();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const oauth_token = urlParams.get('oauth_token')
        const code_token = code || oauth_token
        const data = {'code': code_token,
            'oauth_verifier': urlParams.get('oauth_verifier')}

        login(provider, data, `${FRONTEND_BASE_URL}/callback/${provider}`)
            .then(res => navigate('/'))
            .catch(err => navigate('/login'));

    }, [navigate, provider, login]);

    return (
        <div className="flex justify-center items-center h-screen w-screen bg-gray-200">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
        </div>
    );
}