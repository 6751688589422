import {FRONTEND_BASE_URL, BACKEND_BASE_URL} from "@/config";
import axios from "axios";

const OAUTH_PROVIDERS = {
    google: {
        url: async ()=> 'https://accounts.google.com/o/oauth2/v2/auth?' + new URLSearchParams({
            scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
            client_id: '777406414772-61qv748m033b64gpaq2jevm1dittfk86.apps.googleusercontent.com',
            redirect_uri: `${FRONTEND_BASE_URL}/callback/google`,
            response_type: 'code'
        })
    },
    twitter: {
        url: async () => {
            const response = await axios.get(
                `${BACKEND_BASE_URL}/api/login/twitter_login_url?redirect_uri=${FRONTEND_BASE_URL}/callback/twitter`)
            return response.data.twitter_url;
        }
    }
}


export async function login(provider) {
    if (Object.prototype.hasOwnProperty.call(OAUTH_PROVIDERS, provider)) {
        window.location.href = await OAUTH_PROVIDERS[provider].url();
    } else {
        console.error(`OAuth provider not found: ${provider}`)
    }
}
