import * as React from "react"

import { cn } from "@/lib/utils"
import {useEffect, useLayoutEffect} from "react";

export interface AdaptiveTextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const AdaptiveTextarea = React.forwardRef<HTMLTextAreaElement, AdaptiveTextareaProps>(
  ({ className, ...props }, ref) => {

    const handleInput = (event: React.SyntheticEvent) => {
      const target = event.target as HTMLTextAreaElement;
      target.style.height = '';
      target.style.height = `${target.scrollHeight}px`;
    };

    useEffect(() => {
      if(ref && 'current' in ref) {
        const textarea = ref.current;
        textarea.style.height = '';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }, [ref]);

    return (
      <textarea
        className={cn(
          "flex w-full resize-none overflow-auto max-h-40 rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        rows={1}
        onInput={handleInput}
        {...props}
      />
    )
  }
)
AdaptiveTextarea.displayName = "AdaptiveTextarea"

export { AdaptiveTextarea }
