import React, {useCallback, useEffect, useState} from "react";
import {BotCreate, useChat, User} from "@/contexts/ChatContext";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { AsyncButton } from "@/components/ui/async-button";
import {BACKEND_BASE_URL} from "@/config";
import {useAuth} from "@/contexts/AuthContext";
import axios from "axios";
import {useNavigate} from "react-router-dom";



export interface BotOnEdit {
    botId : string;
    nickname: string;
    username?: string;
    avatarUrl?: string;
    systemPrompt?: string;
    reminderPrompt?: string;
}
interface EditBotDialogProps {
    botId: string;
    open: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onUpdated: () => void;
}

const EditBotDialog: React.FC<EditBotDialogProps> = ({ botId,open,setIsOpen,onUpdated }) => {
    const [nickname, setNickname] = useState('');
    const [username, setUsername] = useState('');
    const {createBot, createChannel} = useChat();
    const navigate = useNavigate();
    const [avatarUrl, setAvatarUrl] = useState('');
    const [systemPrompt, setSystemPrompt] = useState('');
    const [reminderPrompt, setReminderPrompt] = useState('');
    const [nicknameError, setNicknameError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [nicknameTouched, setNicknameTouched] = useState(false);
    const [usernameTouched, setUsernameTouched] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const {jwt} = useAuth();
    useEffect(() => {
        async function loadBot() {
            try {
                const response = await axios.get(
                    `${BACKEND_BASE_URL}/api/users/bot/${botId}`,
                    {headers: {"Authorization": `Bearer ${jwt}`}},
                );
                const botUser = response.data;
                console.log({botUser});
                setNickname(botUser.nickname);
                setUsername(botUser.username);
                setSystemPrompt(botUser.system_prompt);
                setReminderPrompt(botUser.reminder_prompt);
            } catch (error) {
                console.log("Error loading bot:------------------ " + botId);
            }

        }
        loadBot();

    }, [botId,open]);

    useEffect(() => {
        if (!open) {
            setNickname('');
            setUsername('');
            setAvatarUrl('');
            setSystemPrompt('');
            setReminderPrompt('');
            setNicknameError('');
            setUsernameError('');
            setNicknameTouched(false);
            setUsernameTouched(false);
        }
    }, [open]);

    useEffect(() => {
        if (nicknameTouched) {
            setNicknameError(nickname.length < 3 ? "At least 3 characters." : "");
        }
    }, [nickname, nicknameTouched]);

    useEffect(() => {
        if (usernameTouched) {
            setUsernameError(username.length < 3 ? "At least 3 characters." : "");
        }
    }, [username, usernameTouched]);

    const handleUpdate = async () => {
        if (!nicknameError && !usernameError) {
            console.log(systemPrompt);

            try {
                const response = await axios.put(
                    `${BACKEND_BASE_URL}/api/users/${botId}`,
                {
                    nickname: nickname,
                    username: username,
                    system_prompt: systemPrompt,
                    reminder_prompt: reminderPrompt
                },
                    {headers: {"Authorization": `Bearer ${jwt}`}},
                );
                setShowSuccessDialog(true);
            } catch (error) {
                console.error("Error updating bot: ", error);
            }

        }
    }

    const startChat = async (user_id) => {
        await createChannel("direct", [user_id]);
        navigate("/chats");
    };
    const handleChat = async () => {
        setIsOpen(false); // Close the create bot dialog
        await startChat(botId);
        setShowSuccessDialog(false);
        return Promise.resolve();
    }
    const handleClose = () => {
        console.log('handleClose was called');
        setIsOpen(false);
        setShowSuccessDialog(false);
        if (onUpdated) {
            console.log('onUpdated is present, about to call');
            onUpdated();
            console.log('onUpdated was called');
        } else {
            console.log('onUpdated is not present, check your prop passing');
        }
        return Promise.resolve();
    }

        return (
            <Dialog open={open} onOpenChange={setIsOpen}>
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Edit a Bot</DialogTitle>
                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="nickname" className="text-right">
                                Nickname
                            </Label>
                            <Input id="nickname" value={nickname} onChange={e => setNickname(e.target.value)}
                                   onBlur={() => setNicknameTouched(true)}
                                   placeholder="Pedro Duarte" className="col-span-3"/>
                            {nicknameError && nicknameTouched &&
                                <p className="col-span-3 text-right text-red-500 text-sm">{nicknameError}</p>}
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="username" className="text-right">
                                Username
                            </Label>
                            <Input id="username" value={username} onChange={e => setUsername(e.target.value)}
                                   onBlur={() => setUsernameTouched(true)}
                                   placeholder="peduarte" className="col-span-3"/>
                            {usernameError && usernameTouched &&
                                <p className="col-span-3 text-right text-red-500 text-sm">{usernameError}</p>}
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="system_prompt" className="text-right">
                                System Prompt
                            </Label>
                            <Textarea id="system_prompt" value={systemPrompt}
                                      onChange={e => setSystemPrompt(e.target.value)}
                                      placeholder="You are a helpful assistant." className="col-span-3"/>
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="reminder_prompt" className="text-right">
                                Reminder Prompt
                            </Label>
                            <Textarea id="reminder_prompt" value={reminderPrompt}
                                      onChange={e => setReminderPrompt(e.target.value)}
                                      placeholder="Reminder AI how to response." className="col-span-3"/>
                        </div>
                    </div>
                    <DialogFooter>
                        <AsyncButton type="submit" onClick={handleUpdate}
                                     disabled={!!(nicknameError || usernameError)}>Update</AsyncButton>
                    </DialogFooter>
                </DialogContent>
                {showSuccessDialog && (
                    <Dialog open={showSuccessDialog} onOpenChange={setShowSuccessDialog}>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>Bot updated successfully!</DialogTitle>
                            </DialogHeader>
                            <DialogFooter>
                                <AsyncButton onClick={handleChat}>CHAT</AsyncButton>
                                <AsyncButton onClick={handleClose}>DONE</AsyncButton>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                )}
            </Dialog>
        )
}

export default EditBotDialog;
