import {Command} from "lucide-react"

import {cn} from "@/lib/utils"
import {buttonVariants} from "@/components/ui/button"
import {UserAuthForm} from "@/components/user-auth-form"
import React from "react"
import {Link} from "react-router-dom";

export default function AuthenticationPage() {
    return (
        <>
            <div className="flex flex-col items-center justify-center min-h-screen bg-background">
                <div className="lg:p-8">
                    <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
                        <div className="flex flex-col space-y-2 text-center">
                            <h1 className="text-2xl font-semibold tracking-tight">
                                Welcome to settei.ai
                            </h1>
                            <p className="text-sm text-muted-foreground">
                                Enter your email below to Login
                            </p>
                        </div>
                        <UserAuthForm/>
                    </div>
                </div>
            </div>
        </>
    )
}