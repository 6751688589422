import {BrowserRouter as Router, Route, Routes, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import CallbackPage from "@/pages/callback";
import AuthenticationPage from "@/pages/authentication";
import MainPage from "@/pages/main";
import {AuthProvider} from "@/contexts/AuthContext";
import {ChatProvider} from "@/contexts/ChatContext";

function RefreshPage() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(-1);
    }, [navigate]);

    return <div>REFRESHING...</div>
}

function App() {
    return (
        <AuthProvider>
            <ChatProvider>
                <Router>
                    <Routes>
                        <Route path="/login" element={<AuthenticationPage/>}/>
                        <Route path="/callback/:provider" element={<CallbackPage/>}/>
                        <Route path="/refresh" element={<RefreshPage/>}/>
                        <Route path="/*" element={<MainPage/>}/>
                    </Routes>
                </Router>
            </ChatProvider>
        </AuthProvider>
    );
}

export default App;
