import React, {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import humps from 'humps';
import produce from 'immer';
import * as uuid from 'uuid';

import {EventSourcePolyfill} from 'event-source-polyfill';

import {useAuth} from '@/contexts/AuthContext';
import {BACKEND_BASE_URL} from "@/config";

export type ChannelType = "direct" | "group";

export interface Channel {
    channelId: string;
    channelType: ChannelType;
    channelName?: string;
    channelAvatarUrl?: string;
    currentConversationId: string;
    uniqueMembers?: string;
    lastActivity: Date;
    channelMembers: ChannelMember[];
    myRole: string;

    messages: Message[];
    initMessagesLoaded: boolean;
    loadingContext: string;
    loadingCount: number;
    unreadCount: number;
}

export interface ChannelMember {
    user: User;
    role: string;
    joinedAt: Date;
}

export interface User {
    userId: string;
    username?: string;
    nickname?: string;
    avatarUrl?: string;
    userType: string;
}

export type MessageType = 'text' | 'image' | 'voice';

export interface TextMessageContent {
    text: string;
    mentions?: string[];
}

export interface ImageMessageContent {
    url: string;
    caption?: string;
}

export interface VoiceMessageContent {
    url: string;
    duration: number;
}

export type MessageContent = TextMessageContent | ImageMessageContent | VoiceMessageContent;

export interface MessageToSend {
    messageType: MessageType;
    content: MessageContent;
}

export interface ChannelUpdate {
    channelName?: string,
    channelAvatarUrl?: string,
    currentConversationId?: string
}

export interface Message {
    messageId: string;
    channelId: string;
    conversationId: string;
    sender: User;
    messageType: MessageType;
    content: MessageContent;
    sentAt: Date;
}

export interface BotCreate {
    nickname: string;
    username?: string;
    avatarUrl?: string;
    systemPrompt?: string;
    reminderPrompt?: string;
}

export interface ChatContextValue {
    channels: Channel[];
    friends: User[];
    friendRequests: User[];
    activeChannelId: string | null;
    selectChannel: (channelId: string) => void;
    sendMessage: (channelId: string, messageToSend: MessageToSend) => Promise<void>;
    updateChannel: (channelId: string, channelUpdate: ChannelUpdate) => Promise<void>;
    createChannel: (channelType: ChannelType, members: string[]) => Promise<void>;
    addMember: (channelId: string, userId: string) => Promise<void>;
    removeMember: (channelId: string, userId: string) => Promise<void>;
    searchUsers: (q: string) => Promise<User[]>;
    sendFriendInvitation: (friendId: string) => Promise<void>;
    acceptFriendRequest: (friendId: string) => Promise<void>;
    rejectFriendRequest: (friendId: string) => Promise<void>;
    createBot: (botCreate: BotCreate) => Promise<User>;
}

export const ChatContext = createContext<ChatContextValue | undefined>(undefined);

// 对象到日期的转换
const toDates = (object: any): any => {
    if (typeof object !== 'object' || object === null) {
        return object;
    }
    for (const key of Object.keys(object)) {
        if (key === 'joinedAt' || key === 'sentAt' || key === 'lastActivity') {
            object[key] = new Date(object[key]);
        } else if (typeof object[key] === 'object') {
            object[key] = toDates(object[key]);
        }
    }
    return object;
};

const convertFromServer = (data) => {
    if (data) {
        data = toDates(humps.camelizeKeys(data));
    }
    return data;
}

const convertToServer = (data) => {
    return humps.decamelizeKeys(data);
}

type Props = {
    children?: React.ReactNode
};
export const ChatProvider: React.FC<Props> = ({children}) => {
    const {user, jwt} = useAuth();
    const [channels, setChannels] = useState<Channel[]>([]);
    const [friends, setFriends] = useState<User[]>([]);
    const [friendRequests, setFriendRequests] = useState<User[]>([]);
    const [activeChannelId, setActiveChannelId] = useState<string | null>(null);

    const getInitialData = useCallback(async () => {
        let response = await axios.get(
          `${BACKEND_BASE_URL}/api/channels/`,
          {headers: {"Authorization": `Bearer ${jwt}`}}
        );
        const channels = convertFromServer(response.data);
        channels.forEach(channel => {
            channel.messages = [];
            channel.initialMessagesLoaded = false;
            channel.loadingContext = uuid.v4();
            channel.loadingCount = 0;
            channel.unreadCount = 0;
        });
        setChannels(channels);

        response = await axios.get(
          `${BACKEND_BASE_URL}/api/friends/`,
          {headers: {"Authorization": `Bearer ${jwt}`}}
        );
        const friends = convertFromServer(response.data);
        setFriends(friends);

        response = await axios.get(
            `${BACKEND_BASE_URL}/api/friends/requests`,
            {headers: {"Authorization": `Bearer ${jwt}`}}
        );
        const friendRequests = convertFromServer(response.data);
        console.log({friendRequests})
        setFriendRequests(friendRequests);
    }, [jwt]);

    const addMessages = useCallback((messages, newMessages) => {
        const existingMessageIds = new Set(messages.map(message => message.messageId));
        const nonExistMessages = newMessages.filter(message => !existingMessageIds.has(message.messageId));
        messages.push(...nonExistMessages);
        messages.sort((a, b) => a.sentAt - b.sentAt);
    }, []);

    const addChannel = useCallback((channels, newChannel) => {
        const exists = channels.exists(x => x.channelId === newChannel.channelId);
        if (!exists) {
            channels.push(newChannel);
        }
    }, []);

    const loadChannelMessages = useCallback(async (channel, {init = true, limit = 50}) => {
        const channelId = channel.channelId;
        const loadingContext = channel.loadingContext;

        setChannels(produce(draftChannels => {
            const channel = draftChannels.find(x => x.channelId === channelId);
            if (channel?.loadingContext === loadingContext) {
                channel.loadingCount += 1;
            } else {
                getInitialData();
            }
        }));

        try {
            const response = await axios.get(
                `${BACKEND_BASE_URL}/api/channels/${channelId}/messages`,
                {
                    headers: {"Authorization": `Bearer ${jwt}`},
                    params: {limit}
                }
            );
            const newMessages = convertFromServer(response.data);
            setChannels(produce(draftChannels => {
                const channel = draftChannels.find(x => x.channelId === channelId);
                if (channel?.loadingContext === loadingContext) {
                    addMessages(channel.messages, newMessages);
                    if (init) {
                        channel.initMessagesLoaded = true;
                    }
                }
            }));
        } finally {
            setChannels(produce(draftChannels => {
                const channel = draftChannels.find(x => x.channelId === channelId);
                if (channel?.loadingContext === loadingContext) {
                    channel.loadingCount -= 1;
                }
            }));
        }
    }, [addMessages, getInitialData, jwt]);

    const handleEvent = useCallback(({ type, payload }) => {
        switch (type) {
            case 'message.new': {
                const newMessage = payload;
                setChannels(produce((draftChannels) => {
                    const channel = draftChannels.find(x => x.channelId === newMessage.channelId);
                    if (channel) {
                        const messages = channel.messages;
                        addMessages(messages, [newMessage]);
                    } else {
                        getInitialData();
                    }
                }));
            }
            break;
            case 'channels.new': {
                const newChannel = payload;
                newChannel.messages = [];
                newChannel.initialMessagesLoaded = false;
                newChannel.loadingContext = uuid.v4();
                newChannel.loadingCount = 0;
                newChannel.unreadCount = 0;
                loadChannelMessages(newChannel, {init: true});

                setChannels(produce(draftChannels => {
                    addChannel(draftChannels, newChannel);
                }));
            }
            break;
            case "conversation.switch": {
                const {channelId, conversationId} = payload;
                setChannels(produce(draftChannels => {
                    const channel = draftChannels.find(x => x.channelId === channelId && x.currentConversationId !== conversationId);
                    if (channel) {
                        channel.currentConversationId = conversationId;

                        channel.messages = [];
                        channel.initMessagesLoaded = false;
                        channel.loadingContext = uuid.v4();
                        channel.loadingCount = 0;
                        channel.unreadCount = 0;
                        loadChannelMessages(channel, {init: true});
                    } else {
                        getInitialData();
                    }
                }));
            }
            break;
            case "member.added": {
                const {channelId, user} = payload;
                setChannels(produce(draftChannels => {
                    const channel = draftChannels.find(x => x.channelId === channelId);
                    if (channel) {
                        channel.channelMembers.push({
                            user,
                            role: 'member',
                            joinedAt: new Date()
                        });
                    } else {
                        getInitialData();
                    }
                }));
            }
            break;
            case "member.removed": {
                const {channelId, user: removedUser} = payload;
                setChannels(produce(draftChannels => {
                    if (removedUser.userId === user.userId) {
                        const index = draftChannels.findIndex(x => x.channelId === channelId);
                        if (index >= 0) {
                            draftChannels.splice(index, 1);
                        }
                    } else {
                        const channel = draftChannels.find(x => x.channelId === channelId);
                        if (channel) {
                            channel.channelMembers = channel.channelMembers.filter(x => x.user.userId !== removedUser.userId);
                        } else {
                            getInitialData();
                        }
                    }
                }));
            }
            break;
            default:
                console.error("Unknown event type", type);
        }
    }, [addChannel, addMessages, getInitialData, loadChannelMessages, user?.userId]);

    useEffect(() => {
        if (user && jwt) {
            getInitialData();
        }
    }, [user, jwt, getInitialData]);

    useEffect(() => {
        let eventSource: EventSourcePolyfill | null = null;
        if (user && jwt) {
            eventSource = new EventSourcePolyfill(`${BACKEND_BASE_URL}/api/channels/stream`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            });
            eventSource.onmessage = (event) => {
                console.debug('[EventSource]', event);
                const { data } = event;
                const parsedData = JSON.parse(data);
                const convertedData = convertFromServer(parsedData);
                handleEvent(convertedData);
            }
        }
        return () => {
            if (eventSource) {
                eventSource.close();
            }
            setChannels([]);
            setActiveChannelId(null);
        };
    }, [user, jwt, handleEvent]);

    const selectChannel = useCallback(async (channelId: string | null) => {
        setActiveChannelId(channelId);

        const selectedChannel = channels.find(x => x.channelId === channelId);
        if (!selectedChannel) return;

        if (!selectedChannel.initMessagesLoaded) {
            loadChannelMessages(selectedChannel, {init: true});
        }
        setChannels(produce(draftChannels => {
            const channel = draftChannels.find(x => x.channelId === channelId);
            if (channel) {
                channel.unreadCount = 0;
            } else {
                getInitialData();
            }
        }));
    }, [channels, getInitialData, loadChannelMessages]);

    const sendMessage = useCallback(async (channelId: string, messageToSend: MessageToSend) => {
        const response = await axios.post(
            `${BACKEND_BASE_URL}/api/channels/${channelId}/messages`,
            convertToServer(messageToSend),
            {headers: {"Authorization": `Bearer ${jwt}`}},
        );
        const message = convertFromServer(response.data);
        setChannels(produce(draftChannels => {
            const channel = draftChannels.find(x => x.channelId === channelId);
            if (channel) {
                addMessages(channel.messages, [message]);
                channel.lastActivity = new Date();
            } else {
                getInitialData();
            }
        }));
    }, [addMessages, getInitialData, jwt]);

    const updateChannel = useCallback(async (channelId: string, channelUpdate: ChannelUpdate) => {
        const response = await axios.put(
          `${BACKEND_BASE_URL}/api/channels/${channelId}`,
          convertToServer(channelUpdate),
          {headers: {"Authorization": `Bearer ${jwt}`}},
        );
        setChannels(produce(draftChannels => {
            const channel = draftChannels.find(x => x.channelId === channelId);
            if (channel) {
                channel.channelName = channelUpdate.channelName || channel.channelName;
                channel.channelAvatarUrl = channelUpdate.channelAvatarUrl || channel.channelAvatarUrl;
                channel.currentConversationId = channelUpdate.currentConversationId || channel.currentConversationId;
            } else {
                getInitialData();
            }
        }));
    }, [getInitialData, jwt]);

    const createChannel = useCallback(async (channelType: string, members: string[]) => {
        const response = await axios.post(
          `${BACKEND_BASE_URL}/api/channels/`,
            convertToServer({channelType, members}),
          {headers: {"Authorization": `Bearer ${jwt}`}},
        );
        const channel = convertFromServer(response.data);
        channel.messages = [];
        channel.initialMessagesLoaded = false;
        channel.loadingContext = uuid.v4();
        channel.loadingCount = 0;
        channel.unreadCount = 0;

        setChannels(produce(draftChannels => {
            if (!draftChannels.find(x => x.channelId === channel.channelId)) {
                draftChannels.push(channel);
            }
        }));

        await selectChannel(channel.channelId);
    }, [jwt, selectChannel]);

    const addMember = useCallback(async (channelId: string, userId: string) => {
        const response = await axios.post(
          `${BACKEND_BASE_URL}/api/channels/${channelId}/members`,
          convertToServer({userId}),
          {headers: {"Authorization": `Bearer ${jwt}`}},
        );
    }, [jwt]);

    const removeMember = useCallback(async (channelId: string, userId: string) => {
        const response = await axios.delete(
          `${BACKEND_BASE_URL}/api/channels/${channelId}/members/${userId}`,
          {headers: {"Authorization": `Bearer ${jwt}`}},
        );
        if (userId === user?.userId) {
            setChannels(produce(draftChannels => {
                const index = draftChannels.findIndex(x => x.channelId === channelId);
                if (index >= 0) {
                    draftChannels.splice(index, 1);
                }
            }));
            const newChannels = channels.filter(x => x.channelId !== channelId).sort((a, b) => a.lastActivity.getTime() - b.lastActivity.getTime());
            selectChannel(newChannels[0]?.channelId || null);
        }
    }, [channels, jwt, selectChannel, user]);

    const searchUsers = useCallback(async (q: string) => {
        const response = await axios.get(
          `${BACKEND_BASE_URL}/api/users/search?q=${q}`,
          {headers: {"Authorization": `Bearer ${jwt}`}},
        );
        return convertFromServer(response.data);
    }, [jwt]);

    const sendFriendInvitation = useCallback(async (friendId: string) => {
        await axios.post(
          `${BACKEND_BASE_URL}/api/friends/requests`,
          convertToServer({friendId}),
          {headers: {"Authorization": `Bearer ${jwt}`}},
        );
    }, [jwt]);

    const acceptFriendRequest = useCallback(async (friendId: string) => {
        await axios.put(
          `${BACKEND_BASE_URL}/api/friends/requests/accept`,
          convertToServer({friendId}),
          {headers: {"Authorization": `Bearer ${jwt}`}},
        );
    }, [jwt]);

    const rejectFriendRequest = useCallback(async (friendId: string) => {
        await axios.put(
          `${BACKEND_BASE_URL}/api/friends/requests/reject`,
          convertToServer({friendId}),
          {headers: {"Authorization": `Bearer ${jwt}`}},
        );
    }, [jwt]);

    const createBot = useCallback(async (botCreate: BotCreate) => {
        const response = await axios.post(
          `${BACKEND_BASE_URL}/api/users/`,
          convertToServer(botCreate),
          {headers: {"Authorization": `Bearer ${jwt}`}},
        );
        const botUser = convertFromServer(response.data);
        console.log({botUser});

        return botUser;

    }, [jwt]);

    const value: ChatContextValue = useMemo((): ChatContextValue => ({
        channels,
        friends,
        friendRequests,
        activeChannelId,
        selectChannel,
        sendMessage,
        updateChannel,
        createChannel,
        addMember,
        removeMember,
        searchUsers,
        sendFriendInvitation,
        acceptFriendRequest,
        rejectFriendRequest,
        createBot,
    }), [channels, friends, friendRequests, activeChannelId, selectChannel, sendMessage, updateChannel, createChannel, addMember, removeMember, searchUsers, sendFriendInvitation, acceptFriendRequest, rejectFriendRequest, createBot]);

    return (
        <ChatContext.Provider value={value}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChat = (): ChatContextValue => {
    const context = React.useContext(ChatContext);
    if (context === undefined) {
        throw new Error('useChat must be used within an ChatProvider');
    }
    return context;
}
