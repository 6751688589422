import * as React from "react";
import {useState} from 'react';
import {cn} from "@/lib/utils";
import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {Icons} from "@/components/icons";
import {login} from "@/lib/oauth";
import {BACKEND_BASE_URL} from "@/config";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {
}

export function UserAuthForm({className, ...props}: UserAuthFormProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [emailSent, setEmailSent] = useState<boolean>(false);

    async function onSubmit(event: React.SyntheticEvent) {
        event.preventDefault();
        setIsLoading(true);

        // 与后端进行交互，请求发送验证邮件
        const success = await sendEmailVerification(email);
        //const success = true;
        if (success) {
            setEmailSent(true);
        }

        setIsLoading(false);
    }

    // 如果邮件已经发送，渲染一个通知说明。否则，渲染输入框和按钮。
    const formContent = emailSent ? (
        <div style={{
            border: '2px solid #ffa500',
            borderRadius: '10px',
            padding: '20px',
            margin: '10px auto',
            maxWidth: '600px',
            textAlign: 'center',
            backgroundColor: '#fff9db',
        }}>
            <p style={{
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#333',
            }}>
                Please check your email {email} for a verification link.
            </p>
        </div>
    ) : (
        <div className="grid gap-2">
            <div className="grid gap-1">
                <Label className="sr-only" htmlFor="email">Email</Label>
                <Input
                    id="email"
                    placeholder="name@example.com"
                    type="email"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    autoCapitalize="none"
                    autoComplete="email"
                    autoCorrect="off"
                    disabled={isLoading}
                />
            </div>
            <Button disabled={isLoading}>
                {isLoading && (
                    <Icons.spinner className="mr-2 h-4 w-4 animate-spin"/>
                )}
                Sign In with Email
            </Button>
        </div>
    );

    return (
        <div className={cn("grid gap-6", className)} {...props}>
            <form onSubmit={onSubmit}>
                {formContent}
            </form>
            <div className="relative">
                <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t"/>
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-background px-2 text-muted-foreground">
                        Or continue with
                    </span>
                </div>
            </div>
            <Button variant="outline" type="button" disabled={isLoading} onClick={e => {
                setIsLoading(true);
                login("google");
            }}>
                {isLoading ? (
                    <Icons.spinner className="mr-2 h-4 w-4 animate-spin"/>
                ) : (
                    <Icons.google className="mr-2 h-4 w-4"/>
                )}{" "}
                Google
            </Button>
            <Button variant="outline" type="button" disabled={isLoading} onClick={e => {
                setIsLoading(true);
                login("twitter");
            }}>
                {isLoading ? (
                    <Icons.spinner className="mr-2 h-4 w-4 animate-spin"/>
                ) : (
                    <Icons.twitter className="mr-2 h-4 w-4"/>
                )}{" "}
                Twitter
            </Button>
        </div>
    );
}

async function sendEmailVerification(email: string) {
    // 将这个 URL 替换为你的后端服务的 URL
    const url = `${BACKEND_BASE_URL}/api/users/send_verification_email`;


        // 将电子邮件地址发送到后端服务
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
    });

    // 如果请求成功，返回 true，否则返回 false
    if (response.ok) {
        return true;
    } else {
        console.error('Failed to send verification email:', await response.text());
        return false;
    }
}