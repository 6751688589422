import React, {useEffect} from "react";
import {Outlet, useLocation, useNavigate, useRoutes} from "react-router-dom";
import axios from "axios";
import {Sidebar} from "@/components/sidebar";
import {BACKEND_BASE_URL} from "@/config";
import {useAuth} from "@/contexts/AuthContext";
import ChatsPage from "@/pages/chats";
import FriendsPage from "@/pages/friends";
import UserProfilePage from "@/pages/profile";

export default function MainPage() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/") {
            axios.get(
                `${BACKEND_BASE_URL}/api/users/me`, {headers: {'Authorization': `Bearer ${localStorage.getItem('jwt')}`}})
                .then(res => navigate("/chats"))
                .catch(err => navigate("/explore"));
        }
    }, [location, navigate]);

    function Contacts() {
        return <h1>CONTACTS</h1>
    }

    function Explore() {
        return (
            <div className="bg-background text-foreground">

                <nav className="bg-background p-4 mb-6">
                    <a href="#" className="text-foreground hover:text-accent hover:bg-muted">NavItem 1</a>
                    <a href="#" className="text-foreground bg-accent text-primary p-2 ml-2">NavItem 2 (Active)</a>
                    <a href="#" className="text-foreground hover:text-accent hover:bg-muted ml-2">NavItem 3</a>
                </nav>

                <div className="bg-card text-foreground p-4 mb-6">
                    <h2 className="text-primary mb-2">Alert Title</h2>
                    <p className="text-secondary mb-2">Some alert text...</p>
                    <button className="bg-destructive text-destructive-foreground">Delete</button>
                    <button className="bg-muted text-muted-foreground ml-2">Cancel</button>
                </div>

                <div className="bg-popover text-foreground p-4 mb-6">
                    <div className="bg-background p-2 mb-2">Message 1</div>
                    <div className="bg-background p-2 mb-2">Message 2</div>
                    <input className="bg-input mb-2" type="text" placeholder="Type a message..."/>
                    <button className="bg-primary text-primary-foreground">Send</button>
                </div>

                <div className="p-4 grid grid-cols-2 gap-4 text-foreground">
                    <div className="bg-background text-foreground">Default</div>
                    <div className="bg-primary text-primary-foreground">Primary</div>
                    <div className="bg-secondary text-secondary-foreground">Secondary</div>
                    <div className="bg-destructive text-destructive-foreground">Destructive</div>
                    <div className="bg-muted text-muted-foreground">Muted</div>
                    <div className="bg-accent text-accent-foreground">Accent</div>
                    <div className="bg-popover">Popover</div>
                    <div className="bg-card">Card</div>
                    <div className="bg-border">Border</div>
                    <div className="bg-input">Input</div>
                    <div className="bg-ring">Ring</div>
                </div>
            </div>
        );
    }


    const element = useRoutes([
        {
            path: 'chats',
            element: <ChatsPage/>,
        },
        {
            path: 'friends',
            element: <FriendsPage/>,
        },
        {
            path: 'explore',
            element: <Explore/>,
        },
        {
            path: 'me',
            element: <UserProfilePage/>
        }
    ]);

    return (
        <div className="flex h-screen overflow-hidden text-sm text-gray-600 bg-neutral-900">
            <Sidebar/>
            <div className="flex flex-1 overflow-hidden h-screen rounded-tl-3xl rounded-bl-3xl bg-white">
                {element || <Outlet/>}
            </div>
        </div>
    );
}
