import React, {useEffect, useState} from "react";
import {BotCreate, useChat, User} from "@/contexts/ChatContext";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger} from "@/components/ui/dialog";
import {Label} from "@/components/ui/label";
import {Input} from "@/components/ui/input";
import {Textarea} from "@/components/ui/textarea";
import {AsyncButton} from "@/components/ui/async-button";
import {useNavigate} from "react-router-dom";

interface CreateBotDialogProps {
    trigger: React.ReactElement;
    onCreate: (botInfo: BotCreate) => Promise<User>;
    onCreated: () => void;
}

const CreateBotDialog: React.FC<CreateBotDialogProps> = ({ trigger, onCreate, onCreated }) => {
    const [open, setOpen] = useState(false);
    const [nickname, setNickname] = useState('');
    const [username, setUsername] = useState('');
    const [avatarUrl, setAvatarUrl] = useState('');
    const [systemPrompt, setSystemPrompt] = useState('');
    const [nicknameError, setNicknameError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [nicknameTouched, setNicknameTouched] = useState(false);
    const [usernameTouched, setUsernameTouched] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);
    const [botUserId, setBotUserId] = useState(null);
    const {createBot, createChannel} = useChat();
    const navigate = useNavigate();
    const [reminderPrompt, setReminderPrompt] = useState(''); // <-- Add this


    useEffect(() => {
        if (!open) {
            setNickname('');
            setUsername('');
            setAvatarUrl('');
            setSystemPrompt('');
            setReminderPrompt('');
            setNicknameError('');
            setUsernameError('');
            setNicknameTouched(false);
            setUsernameTouched(false);
        }
    }, [open]);

    useEffect(() => {
        if (nicknameTouched) {
            setNicknameError(nickname.length < 3 ? "At least 3 characters." : "");
        }
    }, [nickname, nicknameTouched]);

    useEffect(() => {
        if (usernameTouched) {
            setUsernameError(username.length < 3 ? "At least 3 characters." : "");
        }
    }, [username, usernameTouched]);

    const handleCreate = async () => {
        if (!nicknameError && !usernameError) {
            const botUser = await onCreate({
                nickname,
                username: username || null,
                avatarUrl: avatarUrl || null,
                systemPrompt: systemPrompt || null,
                reminderPrompt: reminderPrompt || null
            });
            setBotUserId(botUser.userId);
            setShowSuccessDialog(true);
        }
    }


    const handleClose = () => {
        console.log('handleClose was called');
        setOpen(false);
        setShowSuccessDialog(false);
        if(onCreated){
            console.log('onCreated is present, about to call');
            onCreated();
            console.log('onCreated was called');
        }else{
            console.log('onCreated is not present, check your prop passing');
        }
        return Promise.resolve();
    }


    const handleChat = async () => {
        setOpen(false); // Close the create bot dialog
        await startChat(botUserId);
        setShowSuccessDialog(false);
        return Promise.resolve();
    }

    const startChat = async (user_id) => {
        await createChannel("direct", [user_id]);
        navigate("/chats");
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                {trigger}
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Create a Bot</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="nickname" className="text-right">
                            Nickname
                        </Label>
                        <Input id="nickname" value={nickname} onChange={e => setNickname(e.target.value)}
                               onBlur={() => setNicknameTouched(true)}
                               placeholder="Pedro Duarte" className="col-span-3"/>
                        {nicknameError && nicknameTouched && <p className="col-span-3 text-right text-red-500 text-sm">{nicknameError}</p>}
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="username" className="text-right">
                            Username
                        </Label>
                        <Input id="username" value={username} onChange={e => setUsername(e.target.value)}
                               onBlur={() => setUsernameTouched(true)}
                               placeholder="peduarte" className="col-span-3"/>
                        {usernameError && usernameTouched && <p className="col-span-3 text-right text-red-500 text-sm">{usernameError}</p>}
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="system_prompt" className="text-right">
                            System Prompt
                        </Label>
                        <Textarea id="system_prompt" value={systemPrompt}
                                  onChange={e => setSystemPrompt(e.target.value)}
                                  placeholder="You are a helpful assistant." className="col-span-3"/>
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="reminder_prompt" className="text-right">
                            Reminder Prompt
                        </Label>
                        <Textarea id="reminder_prompt" value={reminderPrompt}
                                  onChange={e => setReminderPrompt(e.target.value)}
                                  placeholder="Reminder AI how to response." className="col-span-3"/>
                    </div>
                </div>
                <DialogFooter>
                    <AsyncButton type="submit" onClick={handleCreate} disabled={!!(nicknameError || usernameError)}>Create</AsyncButton>
                </DialogFooter>
            </DialogContent>
            {showSuccessDialog && (
                <Dialog open={showSuccessDialog} onOpenChange={setShowSuccessDialog}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Bot created successfully!</DialogTitle>
                        </DialogHeader>
                        <DialogFooter>
                            <AsyncButton onClick={handleChat}>CHAT</AsyncButton>
                            <AsyncButton onClick={handleClose}>DONE</AsyncButton>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            )}
        </Dialog>
    )
}

export default CreateBotDialog;
