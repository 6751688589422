import React, {useCallback, useEffect, useMemo, useState} from 'react';
import axios from "axios";
import humps from 'humps';
import {BACKEND_BASE_URL} from "@/config";

export interface AuthUser {
    userId: string
    username?: string
    email?: string
    nickname?: string
    avatarUrl?: string
    userType: string
    dio?: string
}

export interface AuthContextValue {
    user: AuthUser | null;
    jwt: string | null;
    isAuthenticated: boolean;
    login: (provider: string, data, redirect_uri: string) => Promise<void>;
    logout: () => void;
    updateUser: (data: Partial<AuthUser>) => Promise<void>;
    loading: boolean;
}

// 创建一个Context，其默认值为空
export const AuthContext = React.createContext<AuthContextValue | undefined>(undefined);

type Props = {
    children?: React.ReactNode
};
export const AuthProvider: React.FC<Props> = ({children}) => {
    const [user, setUser] = useState<AuthUser | null>(null);
    const [jwt, setJwt] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);


    const login = useCallback(async (provider: string, data, redirect_uri: string) => {
        const loginResponse = await axios.post(
            `${BACKEND_BASE_URL}/api/login/${provider}`,
            {authorization_code: data.code, redirect_uri: redirect_uri,authorization_verifier: data.oauth_verifier}
        );
        const jwt = loginResponse.data.jwt;
        localStorage.setItem('jwt', jwt);
        const meResponse = await axios.get(
            `${BACKEND_BASE_URL}/api/users/me`,
            {headers: {'Authorization': `Bearer ${jwt}`}}
        );
        const user: AuthUser = humps.camelizeKeys(meResponse.data);
        localStorage.setItem('user', JSON.stringify(user));
        setJwt(jwt);
        setUser(user);
    }, []);

    const updateUser = useCallback(async (data: Partial<AuthUser>) => {
        const response = await axios.put(
            `${BACKEND_BASE_URL}/api/users/me`,
            humps.decamelizeKeys(data),
            {headers: {'Authorization': `Bearer ${jwt}`}}
        );
        if (response.status !== 200) {
            // Handle error...
            return;
        }

        // 更新存储和状态的用户数据
        const updatedUser: AuthUser = humps.camelizeKeys(response.data);
        localStorage.setItem('user', JSON.stringify(updatedUser));
        setUser(updatedUser);
    }, [jwt]);

    const logout = useCallback(() => {
        localStorage.removeItem('jwt');
        localStorage.removeItem('user');
        setJwt(null);
        setUser(null);
    }, []);

    useEffect(() => {
        const jwt = localStorage.getItem('jwt');
        const userData = localStorage.getItem('user');
        if (jwt && userData) {
            const user = humps.camelizeKeys(JSON.parse(userData));
            setUser(user);
            setJwt(jwt);
        }
        setLoading(false);
    }, []);

    const value: AuthContextValue = useMemo((): AuthContextValue => ({
        user,
        jwt,
        isAuthenticated: user !== null,
        login,
        logout,
        updateUser,
        loading,
    }), [user, jwt, login, logout, updateUser, loading]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextValue => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};