import { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogTrigger, DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

export const ShareDialog = ({ trigger, userLink }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(userLink).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // reset after 2 seconds
        });
    };

    return (
        <Dialog>
            <DialogTrigger asChild>
                {trigger}
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogTitle>Share your profile</DialogTitle>
                <div className="py-4">
                    <div className="items-center">
                        <p>{userLink}</p>
                    </div>
                </div>
                <DialogFooter>
                    <Button type="button" onClick={handleCopy}>
                        {copied ? 'Copied!' : 'Copy to clipboard'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};