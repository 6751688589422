import * as React from "react"
import {Slot} from "@radix-ui/react-slot"
import {cva, VariantProps} from "class-variance-authority"

import {cn} from "@/lib/utils"
import {useEffect, useState} from "react";
import {Icons} from "@/components/icons";
import {Check, Loader2} from "lucide-react";

const buttonVariants = cva(
    "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background",
    {
        variants: {
            variant: {
                default: "bg-primary text-primary-foreground hover:bg-primary/90",
                destructive:
                    "bg-destructive text-destructive-foreground hover:bg-destructive/90",
                outline:
                    "border border-input hover:bg-accent hover:text-accent-foreground",
                secondary:
                    "bg-secondary text-secondary-foreground hover:bg-secondary/80",
                ghost: "hover:bg-accent hover:text-accent-foreground",
                link: "underline-offset-4 hover:underline text-primary",
            },
            size: {
                default: "h-10 py-2 px-4",
                sm: "h-9 px-3 rounded-md",
                lg: "h-11 px-8 rounded-md",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    }
)

export interface AsyncButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean
    onClick: () => Promise<void>
}

const AsyncButton = React.forwardRef<HTMLButtonElement, AsyncButtonProps>(
    ({className, variant, size, asChild = false, ...props}, ref) => {

        const {onClick, children, disabled} = props
        const Comp = asChild ? Slot : "button"

        const [loading, setLoading] = useState(false)
        const [success, setSuccess] = useState(false)

        const newChildren = loading
          ? (<><Loader2 className="mr-2 h-4 w-4 animate-spin"/>{children}</>)
          : success
            ? (<><Check className="mr-2 h-4 w-4"/>{children}</>)
            : children

        const handleClick = async () => {
          setLoading(true);
          try {
            await onClick();
            setLoading(false);
            setSuccess(true);
          } catch (error) {
            setLoading(false);
          }
        };

        const newProps = {...props, onClick: handleClick, children: newChildren, disabled: disabled || loading || success}

        useEffect(() => {
          setLoading(false)
          setSuccess(false)
        }, [onClick])

      return (
            <Comp
                className={cn(buttonVariants({variant, size, className}))}
                ref={ref}
                {...newProps}
            />
        )
    }
)
AsyncButton.displayName = "Button"

export {AsyncButton, buttonVariants}
