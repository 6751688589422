import {Contact, MessagesSquare, SatelliteDish, User, LogOutIcon, Users2, LogInIcon} from "lucide-react"

import {cn} from "@/lib/utils"

import {Link, useMatch} from "react-router-dom";
import React, {ElementType, useContext} from "react";
import {AuthContext, useAuth} from "@/contexts/AuthContext";


interface NavItemProps {
    to: string;
    icon: ElementType;
    text: string;
}

const NavItem: React.FC<NavItemProps> = ({to, icon: Icon, text}) => {
    const match = useMatch(to);
    return (
        <Link to={to} className={cn("flex flex-col h-14 w-14 text-xs items-center justify-between rounded-xl p-1", match ? "text-white hover:bg-neutral-800" : "text-neutral-500 hover:bg-neutral-700 hover:text-white")}>
            <Icon size={27}/>
            {text}
        </Link>
    );
}


export function Sidebar() {
    const {user, isAuthenticated} = useAuth();
    const  logout = useContext(AuthContext).logout;
    const handleLogout = () => {
        logout();
        window.location.reload();
    }
    const handleLogin = () => {
        // 这里添加登录按钮的处理逻辑，比如导航到登录页面
        window.location.href = '/login';
    }
    return (
        <div className="w-20 flex-shrink-0 flex-col bg-neutral-900 text-neutral-600 flex">
            <div className="flex h-16 items-center justify-center text-blue-500">
                <svg className="w-9 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 33">
                    <path fillRule="evenodd"
                          d="M27 0c-7.2 0-11.7 3.6-13.5 10.8 2.7-3.6 5.85-4.95 9.45-4.05 2.054.513 3.522 2.004 5.147 3.653C30.744 13.09 33.808 16.2 40.5 16.2c7.2 0 11.7-3.6 13.5-10.8-2.7 3.6-5.85 4.95-9.45 4.05-2.054-.513-3.522-2.004-5.147-3.653C36.756 3.11 33.692 0 27 0zM13.5 16.2C6.3 16.2 1.8 19.8 0 27c2.7-3.6 5.85-4.95 9.45-4.05 2.054.514 3.522 2.004 5.147 3.653C17.244 29.29 20.308 32.4 27 32.4c7.2 0 11.7-3.6 13.5-10.8-2.7 3.6-5.85 4.95-9.45 4.05-2.054-.513-3.522-2.004-5.147-3.653C23.256 19.31 20.192 16.2 13.5 16.2z"
                          clipRule="evenodd"/>
                </svg>
            </div>
            <div className="mx-auto mt-4 flex flex-grow flex-col space-y-4">
                <NavItem to="chats" icon={MessagesSquare} text="Chats"/>
                <NavItem to="friends" icon={Users2} text="Friends"/>
                <NavItem to="explore" icon={SatelliteDish} text="Explore"/>
                <NavItem to="me" icon={User} text="Profile"/>
            </div>
            <div className="mx-auto mb-8 flex items-center justify-center">
                {isAuthenticated ? (
                    <button onClick={handleLogout} className="flex items-center justify-center flex-col">
                        <LogOutIcon />
                        <span>Logout</span>
                    </button>
                ) : (
                    <button onClick={handleLogin} className="flex items-center justify-center flex-col">
                        <LogInIcon />
                        <span>Login</span>
                    </button>
                )}
            </div>
        </div>
    )
}
