import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import ReactMarkdown from "react-markdown";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {darcula} from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

import {BotCreate, Channel, ChannelType, Message, TextMessageContent, useChat, User} from "@/contexts/ChatContext";
import {Input} from "@/components/ui/input";
import {AdaptiveTextarea} from "@/components/ui/adaptive-textarea";
import {Button} from "@/components/ui/button";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem,} from "@/components/ui/command";
import {Popover, PopoverContent, PopoverTrigger,} from "@/components/ui/popover";
import {Bot, Check, Crown, Edit3, Plus, Search, Send, UserMinus2, XCircle} from "lucide-react";
import {Avatar, AvatarFallback, AvatarImage} from "@/components/ui/avatar";
import {cn} from "@/lib/utils";
import {useAuth} from "@/contexts/AuthContext";
import {Checkbox} from "@/components/ui/checkbox";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger} from "@/components/ui/dialog";
import {Label} from "@/components/ui/label";
import {Textarea} from "@/components/ui/textarea";
import {AsyncButton} from "@/components/ui/async-button";
import CreateBotDialog from "@/components/createBotDialog";


interface ChatBubbleProps {
  message: Message
}

const ChatBubble: React.FC<ChatBubbleProps> = ({message}) => {
  const {user} = useAuth();
  const left = message.sender.userId !== user.userId;
  const nickName = message.sender.nickname;
  const avatarUrl = message.sender.avatarUrl;
  const textContent = (message.content as TextMessageContent).text;

  return (
    <div className={cn("flex items-end", left ? "" : "flex-row-reverse")}>
      <Avatar>
        <AvatarImage src={avatarUrl}/>
        <AvatarFallback className="uppercase font-bold text-sm">{(nickName || "").slice(0, 1)}</AvatarFallback>
      </Avatar>
      <div className={cn(
        "text-sm overflow-hidden relative p-4 before:absolute before:bottom-0 before:bg-inherit before:h-4 before:w-4 before:mask-alpha before:mask-full before:mask-no-repeat",
        left ? "bg-white rounded-r-2xl rounded-tl-2xl ml-4 before:-left-4 before:mask-bubble-left"
          : "bg-green-300 rounded-l-2xl rounded-tr-2xl mr-4 before:-right-4 before:mask-bubble-right")}>
        <ReactMarkdown
          remarkPlugins={[remarkMath]}
          rehypePlugins={[rehypeKatex]}
          components={{
            code({node, inline, className, children, ...props}) {
              const match = /language-(\w+)/.exec(className || '')
              return !inline && match ? (
                <SyntaxHighlighter
                  {...props}
                  style={darcula}
                  language={match[1]}
                  PreTag="div"
                >{String(children).replace(/\n$/, '')}</SyntaxHighlighter>
              ) : (
                <code {...props} className={className}>
                  {children}
                </code>
              )
            }
          }}
          className="prose prose-sm"
        >{textContent}</ReactMarkdown>
      </div>
    </div>
  );
}

interface UserAvatarProps {
  user: User;
}

const UserAvatar: React.FC<UserAvatarProps> = ({user}) => {
  return (
    <Avatar>
      <AvatarImage src={user.avatarUrl} />
      <AvatarFallback className="uppercase text-sm">{user.nickname[0]}</AvatarFallback>
    </Avatar>
  );
}

interface ChannelAvatarProps {
  channel: Channel;
}

const ChannelAvatar: React.FC<ChannelAvatarProps> = ({channel}) => {
  const {user} = useAuth();

  const avatarUrl = getAvatarUrl(channel, user);
  if (!avatarUrl && channel.channelType === "group") {
    const members = channel.channelMembers.slice(0, 4);
    return (
      <Avatar className="grid grid-cols-2 bg-muted">
        {members.map(x => (
          <Avatar className="w-5 h-5 rounded-none" key={x.user.userId}>
            <AvatarImage src={x.user.avatarUrl}/>
            <AvatarFallback className="uppercase text-xs rounded-none">{x.user.nickname[0]}</AvatarFallback>
          </Avatar>
        ))}
      </Avatar>
    );
  }

  return (
    <Avatar>
      <AvatarImage src={getAvatarUrl(channel, user)}/>
      <AvatarFallback
        className="uppercase font-bold text-sm">{getDisplayName(channel, user).slice(0, 1)}</AvatarFallback>
    </Avatar>
  );
}

interface ChannelTitleProps {
  channel: Channel;
  updateChannelName: (channelId: string, newName: string) => void;
}

const ChannelTitle: React.FC<ChannelTitleProps> = ({channel, updateChannelName}) => {
  const {user} = useAuth();
  const [editing, setEditing] = useState(false);
  const [editingTitle, setEditingTitle] = useState("");
  const inputRef = useRef(null);
  const displayName = getDisplayName(channel, user);

  const handleChange = useCallback(e => setEditingTitle(e.target.value || ""), []);

  const handleKeyDown = useCallback(e => {
    if (e.key === 'Enter') {
      updateChannelName(channel.channelId, editingTitle);
      setEditing(false);
    } else if (e.key == 'Escape') {
      setEditing(false);
    }
  }, [channel.channelId, editingTitle, updateChannelName]);

  const handleEditIconClick = useCallback(e => {
    if (channel.channelType === "direct") {
      return;
    }
    setEditingTitle(channel.channelName || "");
    setEditing(true);
    setTimeout(() => inputRef?.current?.focus(), 0);
  }, [channel.channelName, channel.channelType]);

  useEffect(() => {
    setEditingTitle(channel.channelName || "");
    setEditing(false);
  }, [channel])

  return (
    <h1 className="flex-1 py-2 pr-2 group font-bold flex items-center space-x-2 whitespace-nowrap overflow-hidden">
      <ChannelAvatar channel={channel}/>
      {editing
        ? <Input className="focus-visible:ring-1 focus-visible:ring-offset-0 p-0 text-base" ref={inputRef}
                 value={editingTitle} onChange={handleChange} onKeyDown={handleKeyDown}/>
        : <span className="overflow-hidden overflow-ellipsis">{displayName}</span>}
      {editing || channel.channelType === "direct"
        ? null
        : <Edit3 size={16} className="shrink-0 text-transparent group-hover:text-neutral-500 cursor-pointer"
                 onClick={handleEditIconClick}/>}
    </h1>
  );
}
interface ChannelMembersProps {
  channel: Channel
}

const ChannelMembers: React.FC<ChannelMembersProps> = ({channel}) => {
  const {user} = useAuth();
  const {friends, activeChannelId, addMember, removeMember, createChannel} = useChat();
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const selfIsAdmin = (channel.channelMembers.find(x => x.user.userId === user.userId)?.role ?? "") === "admin";

  const candidates = useMemo(() => {
    const users = [];
    users.push(...friends);
    channel.channelMembers.forEach(member => {
      if (!users.some(x => x.userId === member.user.userId)) {
        users.push(member.user);
      }
    });
    return users;
  }, [channel.channelMembers, friends]);

  const filteredFriends = candidates.filter(x => !search || x.nickname.toLowerCase().includes(search.toLowerCase()));

  const handleOpenChange = useCallback(open => {
    setOpen(open);
    setSearch("");
  }, [setOpen, setSearch]);

  const handleAddMember = useCallback(async (userId) => {
    if (channel.channelType === "direct") {
      const memberUserIds = channel.channelMembers.map(x => x.user.userId);
      await createChannel("group", [...memberUserIds, userId]);
    } else {
      await addMember(activeChannelId, userId);
      setOpen(false);
    }
  }, [activeChannelId, channel, addMember, createChannel]);

  const handleRemoveMember = useCallback(async (userId) => {
    await removeMember(activeChannelId, userId);
    setOpen(false);
  }, [activeChannelId, removeMember]);

  return (
    <div className="flex items-center space-x-2">
      <Popover open={open} onOpenChange={handleOpenChange}>
        <PopoverTrigger asChild>
          <div className="h-5 text-sm text-neutral-500 flex select-none cursor-pointer space-x-1 items-center border-b border-transparent hover:border-neutral-500">
            <Plus size="16"/><span>Invite</span>
          </div>
        </PopoverTrigger>
        <PopoverContent className="p-0">
          <Command shouldFilter={false}>
            <CommandInput value={search} onValueChange={setSearch} placeholder="Invite a friend..." />
            <CommandEmpty>No friend found.</CommandEmpty>
            <CommandGroup>
              {filteredFriends.map((friend) => {
                const member = channel.channelMembers.find(x => x.user.userId === friend.userId);
                const isMember = !!member;
                const isAdmin = member && member.role === 'admin';
                const isSelf = friend.userId === user.userId;
                return (
                  <CommandItem
                    className="group aria-selected:cursor-pointer"
                    key={friend.userId}
                    value={friend.userId}
                    disabled={isMember}
                    onSelect={handleAddMember}>
                    <Check className={cn("mr-2 h-4 w-4", isMember ? "opacity-100" : "opacity-0")} />
                    <div className="relative">
                      <UserAvatar user={friend} />
                      {isAdmin ? <Crown size={15} className="absolute -top-1 -right-1 rotate-45 text-yellow-500"/> : null}
                    </div>
                    <span className="ml-1 overflow-hidden whitespace-nowrap overflow-ellipsis">{friend.nickname}</span>
                    <div className="flex-1 flex justify-end ml-1 shrink-0">
                      <Button variant="outline" title={"Remove member"}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveMember(friend.userId);
                              }}
                              className={cn("h-6 w-6 shrink-0 p-0 rounded-full invisible",
                                isMember && (selfIsAdmin || isSelf) ? "group-hover:visible hover:bg-destructive hover:text-destructive-foreground" : "")}>
                        <UserMinus2 size="14"/>
                      </Button>
                    </div>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>

      <div className="flex -space-x-3 items-center">
        {channel.channelMembers.slice(0, 4).map((x) => (
          <Avatar key={x.user.userId} className="h-7 w-7 border-2">
            <AvatarImage src={x.user.avatarUrl}/>
            <AvatarFallback className="uppercase text-xs rounded-none">{x.user.nickname[0]}</AvatarFallback>
          </Avatar>
        ))}
        {channel.channelMembers.length > 4
          ? (
            <Avatar className="h-7 w-7 border-2">
              <AvatarFallback
                className="text-xs bg-white">+{channel.channelMembers.length - 4}</AvatarFallback>
            </Avatar>
          )
          : null
        }
      </div>
    </div>
  );
}

interface ChatProps {
  channel: Channel
}

const Chat: React.FC<ChatProps> = ({channel}) => {
  const {sendMessage, updateChannel} = useChat();
  const [messageContent, setMessageContent] = useState("");

  const bottomRef = useRef(null);
  const inputRef = useRef(null);

  const resetInputHeight = useCallback(() => {
    if (inputRef && 'current' in inputRef) {
      inputRef.current.style.height = '0px';
    }
  }, [inputRef]);

  const handleChangeMessage = useCallback((e) => {
    setMessageContent(e.target.value);
  }, []);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (messageContent.trim() !== "") {
        sendMessage(channel.channelId, {messageType: "text", content: {text: messageContent, mentions: []}});
        setMessageContent("");
        resetInputHeight();
      }
    }
  }, [channel.channelId, messageContent, resetInputHeight, sendMessage]);

  const handleSendMessage = useCallback(() => {
    if (messageContent.trim() !== "") {
      sendMessage(channel.channelId, {messageType: "text", content: {text: messageContent, mentions: []}});
      setMessageContent("");
      resetInputHeight();
    }
  }, [channel.channelId, messageContent, resetInputHeight, sendMessage]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({behavior: "auto"});
  }, [channel.messages]);

  return (
    <div className="flex flex-col flex-1 mr-2 overflow-hidden">
      <div className="h-20 shrink-0 overflow-hidden flex px-4 space-x-2 items-center justify-between select-none">
        <ChannelTitle channel={channel}
                      updateChannelName={(channelId, newName) => updateChannel(channelId, {channelName: newName})}/>
        <ChannelMembers channel={channel}/>
      </div>
      <div className="flex flex-col flex-1 overflow-hidden rounded-xl bg-neutral-100 mb-2">
        <div className="flex flex-1 flex-col overflow-y-scroll py-4 px-8">
          <div className="flex-1 space-y-3 overflow-x-hidden">
            {channel.messages.map((message) => (
              <ChatBubble key={message.messageId} message={message}/>
            ))}
            <div ref={bottomRef}/>
          </div>
        </div>
        <div className="shrink-0 py-2 px-8 flex space-x-2">
          <AdaptiveTextarea ref={inputRef}
                            className="bg-white rounded-xl text-sm min-h-[38px] h-0 focus-visible:ring-1 focus-visible:ring-offset-0"
                            placeholder="Your message" onChange={handleChangeMessage} value={messageContent}
                            onKeyDown={handleKeyDown}/>
          <Button variant="ghost" className="h-9 w-9 shrink-0 p-0 rounded-full hover:bg-neutral-800 hover:text-white"
                  onClick={handleSendMessage}><Send size="18"/></Button>
        </div>
      </div>
    </div>
  );
}

interface UserListProps {
  users: User[];
  selectedUsers: User[];
  onSelect: (selectedUsers: User[]) => void;
}

export const UserList: React.FC<UserListProps> = ({ users, selectedUsers, onSelect }) => {
  const [search, setSearch] = useState('');

  const handleCheckboxChange = (checked: boolean, user: User) => {
    let updatedSelectedUsers;
    if (checked) {
      updatedSelectedUsers = [...selectedUsers, user];
    } else {
      updatedSelectedUsers = selectedUsers.filter((u) => u.userId !== user.userId);
    }
    onSelect(updatedSelectedUsers);
  }

  const filteredUsers = users.filter((user) =>
    user.nickname.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Command shouldFilter={false} className="border">
      <CommandInput value={search} onValueChange={setSearch} placeholder="Search user..." />
      <CommandGroup className="overflow-y-auto">
        {filteredUsers.map((user) => {
          const isChecked = selectedUsers.some((u) => u.userId === user.userId);
          return (
            <CommandItem
              className="group aria-selected:cursor-pointer"
              key={user.userId}
              value={user.userId}
              onSelect={() => handleCheckboxChange(!isChecked, user)}
            >
              <Checkbox checked={isChecked} className="mr-2" />
              <div className="relative">
                <Avatar>
                  <AvatarImage src={user.avatarUrl || ''} alt={user.nickname} />
                  <AvatarFallback className="uppercase">{user.nickname.charAt(0)}</AvatarFallback>
                </Avatar>
              </div>
              <span className="ml-1 overflow-hidden whitespace-nowrap overflow-ellipsis ">{user.nickname}</span>
            </CommandItem>
          );
        })}
      </CommandGroup>
    </Command>
  );
}

interface UserSelectDialogProps {
  trigger: React.ReactElement;
  users: User[];
  onSelected: (selectedUsers: User[]) => void;
  title: string;
  confirmButtonText: string;
}

const UserSelectDialog: React.FC<UserSelectDialogProps> = ({ trigger, users, onSelected, title, confirmButtonText }) => {
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      // Dialog is closed, reset the selected users
      setSelectedUsers([]);
    }
  }, [open]);

  const handleCheckboxChange = (checked: boolean, user: User) => {
    let updatedSelectedUsers;
    if (!checked) {
      updatedSelectedUsers = selectedUsers.filter((u) => u.userId !== user.userId);
    } else {
      updatedSelectedUsers = [...selectedUsers, user];
    }
    setSelectedUsers(updatedSelectedUsers);
  }

  const handleConfirmSelection = () => {
    onSelected(selectedUsers);
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:w-[600px] sm:max-w-[600px] text-sm sm:rounded-xl">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="flex py-2 space-x-4">
          <div className="h-[400px] flex-1 px-2">
            <UserList users={users} selectedUsers={selectedUsers} onSelect={setSelectedUsers} />
          </div>
          <div className="h-[400px] overflow-y-auto flex space-x-2 flex-1 p-2">
            {selectedUsers.map((user) => (
              <div key={user.userId} className="flex items-center flex-col">
                <div className="relative">
                  <Avatar>
                    <AvatarImage src={user.avatarUrl || ''} alt={user.nickname} />
                    <AvatarFallback>{user.nickname.charAt(0)}</AvatarFallback>
                  </Avatar>
                  <XCircle
                    size={15}
                    className="bg-neutral-500 text-white rounded-full absolute top-0 -right-1 cursor-pointer"
                    onClick={() => handleCheckboxChange(false, user)}
                  />
                </div>
                <span>{user.nickname}</span>
              </div>
            ))}
          </div>
        </div>
        <DialogFooter>
          <Button type="button" onClick={handleConfirmSelection}>{confirmButtonText}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};



export default function ChatsPage() {
  const {user} = useAuth();
  const {friends, channels: unsortedChannels = [], activeChannelId, selectChannel, createChannel, createBot} = useChat();

  const channels = [...unsortedChannels].sort((a, b) => b.lastActivity.getTime() - a.lastActivity.getTime());
  const activeChannel = channels.find(x => x.channelId === activeChannelId);

  useEffect(() => {
    if (!activeChannel && channels.length > 0) {
      selectChannel(channels[0].channelId);
    }
  }, [activeChannel, channels, selectChannel]);

  return (
    <div className="flex flex-1 h-screen text-base overflow-hidden">
      <div className="flex shrink-0 flex-col w-80">
        <div className="relative h-20 shrink-0 px-4 flex space-x-2 items-center">
          <Search className="absolute top-8 left-8 h-4 w-4"/>
          <Input
            className="h-9 text-xs pl-8 rounded-xl bg-neutral-100 focus-visible:ring-1 focus-visible:ring-offset-0 focus-visible:bg-white"
            placeholder="Search"/>
          <CreateBotDialog
            trigger={
              <Button variant="ghost" className="h-8 w-8 shrink-0 p-0 rounded-full hover:shadow text-neutral-500 hover:bg-neutral-800 hover:text-white">
                <Bot size="16"/>
              </Button>
            }
            onCreate={createBot}
            onCreated={()=>{return}}
          />
          <UserSelectDialog
            trigger={
              <Button variant="ghost" className="h-8 w-8 shrink-0 p-0 rounded-full hover:shadow text-neutral-500 hover:bg-neutral-800 hover:text-white">
                <Plus size="16"/>
              </Button>
            }
            users={friends}
            onSelected={async users => {
              const channelType: ChannelType = users.length === 1 ? "direct" : "group";
              const members = users.map(x => x.userId);
              await createChannel(channelType, members);
            }}
            title={"Start a New Group Chat"}
            confirmButtonText={"Start Chat"}
          />

        </div>
        <div className="overflow-y-auto p-2 space-y-1 select-none">
          {channels.map((channel, i) => {
            const active = channel.channelId === activeChannelId;
            const displayName = getDisplayName(channel, user);
            return (
              <div key={channel.channelId + i}
                   className={cn("h-16 p-2 flex space-x-2 items-center overflow-hidden cursor-pointer rounded-xl hover:bg-neutral-100",
                     active ? "bg-neutral-100" : "")}
                   onClick={() => selectChannel(channel.channelId)}>
                <ChannelAvatar channel={channel}/>
                <div className="flex flex-col justify-center overflow-hidden">
                  <h1 className="overflow-hidden whitespace-nowrap overflow-ellipsis">{displayName}</h1>
                  <p
                    className="text-neutral-500 text-xs h-4 overflow-hidden whitespace-nowrap overflow-ellipsis">{(channel.messages.at(-1)?.content as TextMessageContent)?.text}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {activeChannel ? <Chat channel={activeChannel} /> : null}
    </div>
  );
}

function getDisplayName(channel: Channel, user: User) {
  if (!channel || !user) {
    return "";
  }

  if (channel.channelType === "direct") {
    const member = channel.channelMembers.filter(x => x.user.userId !== user.userId)[0];
    return member.user.nickname || "";
  } else {
    return channel.channelName || channel.channelMembers.filter(x => x).slice(0, 4).map(x => x.user.nickname).join(", ");
  }
}

function getAvatarUrl(channel: Channel, user: User) {
  return channel.channelType === "direct"
    ? channel.channelMembers.filter(x => x.user.userId !== user.userId)[0].user.avatarUrl || ""
    : channel.channelAvatarUrl || "";
}
