import React, {useState, useEffect, useCallback} from 'react';
import { User, useChat } from '@/contexts/ChatContext';
import {Plus, Search, User as UserIcon} from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import {Button} from "@/components/ui/button";
import {useNavigate} from "react-router-dom";
import {Dialog, DialogContent, DialogTitle, DialogTrigger} from "@/components/ui/dialog";
import {Command, CommandGroup, CommandInput, CommandItem, CommandLoading} from "@/components/ui/command";
import {useAuth} from "@/contexts/AuthContext";
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from "@/components/ui/accordion";
import {AsyncButton} from "@/components/ui/async-button";

interface UserProfileProps {
  user: User | null;
  onAddFriend: (friend: User) => Promise<void>;
}

const UserProfile: React.FC<UserProfileProps> = ({ user, onAddFriend }) => {
  if (!user) return <div className="flex flex-1 justify-center items-center"><UserIcon size="40" /></div>

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <Avatar className="mb-4">
        <AvatarImage src={user.avatarUrl || ''} alt={user.nickname} />
        <AvatarFallback>{user.nickname.charAt(0)}</AvatarFallback>
      </Avatar>
      <div className="text-center">
        <h2 className="text-2xl">{user.nickname}</h2>
        <h3 className="text-neutral-500">{user.username ? "@" + user.username : "No username"}</h3>
        <AsyncButton className="mt-4" onClick={() => onAddFriend(user)}>Add Friend</AsyncButton>
      </div>
    </div>
  );
};
interface UserListProps {
  selectedUser: User | null;
  onSelect: (selectedUser: User) => void;
}

export const UserList: React.FC<UserListProps> = ({ selectedUser, onSelect }) => {
  const {user: me} = useAuth();
  const {searchUsers, friends} = useChat();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = React.useState(false)
  const [users, setUsers] = useState([] as User[]);

  useEffect(() => {
    if (!search) {
      return;
    }

    let cancel = false;

    setLoading(true);
    setUsers([]);
    const timeout = setTimeout(async () => {
      if (cancel) return;
      try {
        const users = await searchUsers(search);
        if (!cancel) {
          setUsers(users);
        }
      } finally {
        if (!cancel) {
          setLoading(false);
        }
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
      cancel = true; // 设置取消标志
    };
  }, [search, searchUsers]);

  const nonFriendUsers = users.filter(user => {
    return !friends.find((friend) => friend.userId === user.userId) && user.userId !== me.userId;
  });
  console.log(nonFriendUsers);

  return (
    <Command shouldFilter={false} className="border">
      <CommandInput value={search} onValueChange={setSearch} placeholder="Search user..." />
      <CommandGroup className="overflow-y-auto">
        {!loading && search && nonFriendUsers.length === 0 && <div className="py-6 h-full text-center text-sm">No users found.</div>}
        {loading && <CommandLoading>Loading...</CommandLoading>}
        {nonFriendUsers.map((user) => {
          return (
            <CommandItem
              className="group aria-selected:cursor-pointer"
              key={user.userId}
              value={user.userId}
              onSelect={() => onSelect(user)}
            >
              <div className="relative">
                <Avatar>
                  <AvatarImage src={user.avatarUrl || ''} alt={user.nickname} />
                  <AvatarFallback>{user.nickname.charAt(0)}</AvatarFallback>
                </Avatar>
              </div>
              <span className="ml-1 overflow-hidden whitespace-nowrap overflow-ellipsis">{user.nickname}</span>
            </CommandItem>
          );
        })}
      </CommandGroup>
    </Command>
  );
}

interface AddFriendDialogProps {
  trigger: React.ReactElement;
}

const AddFriendDialog: React.FC<AddFriendDialogProps> = ({trigger}) => {
  const {sendFriendInvitation} = useChat();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [open, setOpen] = useState(false);

  const handleAddFriend = useCallback(async (friend) => {
    await sendFriendInvitation(friend.userId);
  }, [sendFriendInvitation]);

  useEffect(() => {
    if (!open) {
      setSelectedUser(null);
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {trigger}
      </DialogTrigger>
      <DialogContent className="sm:w-[600px] sm:max-w-[600px] text-sm sm:rounded-xl">
        <DialogTitle>Add Friend</DialogTitle>
        <div className="flex">
          <div className="w-1/2 h-[300px]">
            <UserList
              selectedUser={selectedUser}
              onSelect={(user) => setSelectedUser(user)}
            />
          </div>
          <div className="w-1/2 p-4 h-[300px] flex">
            <UserProfile user={selectedUser} onAddFriend={handleAddFriend} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};


interface FriendListProps {
  friends: User[];
  requests: User[];
  onSelect: (selectedFriend: User, isRequest: boolean) => void;
}

const FriendList: React.FC<FriendListProps> = ({ friends, requests, onSelect }) => {
  const [search, setSearch] = useState('');

  const filteredFriends = friends.filter((friend) =>
    friend.nickname.toLowerCase().includes(search.toLowerCase())
  );

  const filteredRequests = requests.filter((request) =>
    request.nickname.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="flex shrink-0 flex-col w-80">
      <div className="relative h-20 shrink-0 px-4 flex space-x-2 items-center">
        <Search className="absolute top-8 left-8 h-4 w-4"/>
        <Input
          onChange={(e) => setSearch(e.target.value)}
          className="h-9 text-xs pl-8 rounded-xl bg-neutral-100 focus-visible:ring-1 focus-visible:ring-offset-0 focus-visible:bg-white"
          placeholder="Search"/>
        <AddFriendDialog
          trigger={(
            <Button variant="ghost" className="h-8 w-8 shrink-0 p-0 rounded-full hover:shadow text-neutral-500 hover:bg-neutral-800 hover:text-white">
              <Plus size="16"/>
            </Button>
          )}
        />
      </div>
      <Accordion type="single" defaultValue="friends" collapsible className="overflow-y-auto p-2 space-y-1 select-none">
        <AccordionItem value="requests">
          <AccordionTrigger className="text-sm">Friend Requests</AccordionTrigger>
          <AccordionContent>
            {filteredRequests.map((request) => (
              <div
                key={request.userId}
                className="h-16 p-2 flex space-x-2 items-center overflow-hidden cursor-pointer rounded-xl"
                onClick={() => onSelect(request, true)}
              >
                <Avatar>
                  <AvatarImage src={request.avatarUrl || ''} alt={request.nickname} />
                  <AvatarFallback>{request.nickname.charAt(0)}</AvatarFallback>
                </Avatar>
                <div className="flex flex-col justify-center overflow-hidden">
                  <h1>{request.nickname}</h1>
                </div>
              </div>
            ))}
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="friends">
          <AccordionTrigger className="text-sm">Friends</AccordionTrigger>
          <AccordionContent>
            {filteredFriends.map((friend) => (
              <div
                key={friend.userId}
                className="h-16 p-2 flex space-x-2 items-center overflow-hidden cursor-pointer rounded-xl"
                onClick={() => onSelect(friend, false)}
              >
                <Avatar>
                  <AvatarImage src={friend.avatarUrl || ''} alt={friend.nickname} />
                  <AvatarFallback>{friend.nickname.charAt(0)}</AvatarFallback>
                </Avatar>
                <div className="flex flex-col justify-center overflow-hidden">
                  <h1>{friend.nickname}</h1>
                </div>
              </div>
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

interface FriendProfileProps {
  friend: User | null;
  isRequest: boolean;
  onChat: () => Promise<void>;
  onAccept: () => Promise<void>;
  onReject: () => Promise<void>;
}

const FriendProfile: React.FC<FriendProfileProps> = ({ friend, isRequest, onChat, onAccept, onReject }) => {
  if (!friend) return <div className="flex flex-1 justify-center items-center"><UserIcon size="40" /></div>

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <Avatar>
        <AvatarImage src={friend.avatarUrl || ''} alt={friend.nickname} />
        <AvatarFallback>{friend.nickname.charAt(0)}</AvatarFallback>
      </Avatar>
      <div className="text-center">
        <h2 className="text-2xl">{friend.nickname}</h2>
        <h3 className="text-neutral-500">{friend.username ? "@" + friend.username : "Username not set"}</h3>
      </div>
      <div className="flex space-x-2 mt-4">
        {isRequest
          ? (<><AsyncButton onClick={onAccept}>Accept</AsyncButton><AsyncButton variant="destructive" onClick={onReject}>Reject</AsyncButton></>)
          : (<Button onClick={onChat}>Start Chatting</Button>)
        }
      </div>
    </div>
  );
};

export default function FriendsPage() {
  const navigate = useNavigate();
  const { friends = [], friendRequests = [], createChannel, acceptFriendRequest, rejectFriendRequest} = useChat();
  const [selectedFriend, setSelectedFriend] = useState<User | null>(null);
  const [isRequestSelected, setIsRequestSelected] = useState(false);

  console.log({friends, friendRequests})

  const startChat = async () => {
    if (selectedFriend) {
      await createChannel("direct", [selectedFriend.userId]);
      navigate("/chats");
    }
  };

  const acceptRequest = async () => {
    if (selectedFriend) {
      await acceptFriendRequest(selectedFriend.userId);
      // 你可能需要更新好友列表和好友请求列表
    }
  };

  const rejectRequest = async () => {
    if (selectedFriend) {
      await rejectFriendRequest(selectedFriend.userId);
      // 你可能需要更新好友列表和好友请求列表
    }
  };

  useEffect(() => {
    if (!selectedFriend && friends.length > 0) {
      setSelectedFriend(friends[0]);
    }
  }, [selectedFriend, friends]);

  return (
    <div className="flex flex-1 h-screen text-base">
      <FriendList
        friends={friends}
        requests={friendRequests}
        onSelect={(friend, isRequest) => {
          setSelectedFriend(friend);
          setIsRequestSelected(isRequest);
        }}
      />
      <div className="flex flex-1 p-4 rounded-xl bg-neutral-100 my-2 mr-2">
        <FriendProfile
          friend={selectedFriend}
          isRequest={isRequestSelected}
          onChat={startChat}
          onAccept={acceptRequest}
          onReject={rejectRequest}
        />
      </div>
    </div>
  );
}

